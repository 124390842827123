import React from 'react';
import PropTypes from 'prop-types';
import MicroFrontend from '../MicroFrontend';
import config from '../../config';

const Vms = ({ history }) => (
  <MicroFrontend history={history} name="Vms" host={config.vmsHost} />
);

Vms.propTypes = {
  history: PropTypes.object,
};

export default Vms;
