import React from 'react';
import {
  Switch,
  HashRouter as Router,
  Route,
  Redirect,
} from 'react-router-dom';
import AdFlyer from './components/AdFlyer';
import Vms from './components/Vms';

const App = () => {
  return (
    <Router>
      <Switch>
        <Route path="/adflyer" component={AdFlyer} />
        <Route path="/vms" component={Vms} />
        <Route exact path="/">
          <Redirect to="/vms" />
        </Route>
      </Switch>
    </Router>
  );
};

export default App;
