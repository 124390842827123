import React from 'react';
import PropTypes from 'prop-types';
import MicroFrontend from '../MicroFrontend';
import config from '../../config';

const AdFlyer = ({ history }) => (
  <MicroFrontend
    history={history}
    name="AdFlyer"
    host={config.adFlyerHost}
  />
);

AdFlyer.propTypes = {
  history: PropTypes.object,
};

export default AdFlyer;
