import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

const MicroFrontend = ({ name, host, history }) => {
  useEffect(() => {
    const scriptId = `micro-frontend-script-${name}`;

    const renderMicroFrontend = () => {
      window[`render${name}`](`${name}-container`, history);
    };

    // if (document.getElementById(scriptId)) {
    //   renderMicroFrontend();
    //   return;
    // }

    const script = document.createElement('script');
    script.id = scriptId;
    script.src = `${host}/main.js`;
    script.onload = renderMicroFrontend;
    document.head.appendChild(script);

    // return () => window[`unmount${name}`](`${name}-container`);
  }, [host, name, history]);

  return <main id={`${name}-container`} />;
};

MicroFrontend.propTypes = {
  name: PropTypes.string.isRequired,
  host: PropTypes.string.isRequired,
  history: PropTypes.object,
};

export default MicroFrontend;
